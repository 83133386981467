import React from 'react'

import { Helmet } from 'react-helmet'

import projectStyles from '../style.module.css'
import styles from './index.module.css'

const Home = () => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>Merchant Bear</title>
        <meta name="description" content="Merchant Bear Landing Page" />
        <meta property="og:title" content="Merchant Bear" />
        <meta property="og:description" content="Merchant Bear Landing Page" />
      </Helmet>
      <div className={styles['Navbar-container']}>
        <div className={styles['Navbar']}>
          <div className={styles['Logo']}>
            <img
              alt="image"
              src="/playground_assets/merchant_bear_logo1-200w.png"
              className={styles['image']}
            />
          </div>
          <div className={styles['Links-container']}>
            <a
              href="#products"
              className={` ${styles['Link']} ${projectStyles['anchor']} `}
            >
              Products
            </a>
            <a
              href="#about"
              className={` ${styles['Link1']} ${projectStyles['anchor']} `}
            >
              About Us
            </a>
            <a
              href="#contact"
              className={` ${styles['Link2']} ${projectStyles['anchor']} `}
            >
              contact
            </a>
          </div>
          <div className={styles['Cta-container']}>
            <a
              href="#contact"
              className={` ${styles['Cta-btn']} ${projectStyles['button']} ${projectStyles['anchor']} `}
            >
              Contact Us
            </a>
            <div className={styles['Burger-menu']}>
              <svg viewBox="0 0 1024 1024" className={styles['icon']}>
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['Hero']}>
        <div className={styles['container01']}>
          <h1 className={styles['text']}>Merchant Bear</h1>
          <h1 className={styles['text01']}>
            Where Quality Meets Affordability
          </h1>
          <span className={styles['text02']}>
            <span>
              <span>
                At Merchant Bear we strive to bring you the best and latest
                products at prices that will make you smile.
              </span>
            </span>
          </span>
          <div className={styles['BtnGroup']}>
            <a
              href="#contact"
              className={` ${styles['link3']} ${projectStyles['button']} `}
            >
              Contact Us
            </a>
          </div>
        </div>
        <img
          alt="image"
          src="/playground_assets/xianjuan-hu-upypcsbici4-unsplash-600w.jpg"
          className={styles['image1']}
        />
      </div>
      <div className={styles['Section-separator']}></div>
      <div id="products" className={styles['Features']}>
        <div className={styles['Heading-container']}>
          <h2
            className={` ${styles['text05']} ${projectStyles['section-Heading']} `}
          >
            Products
          </h2>
          <span
            className={` ${styles['Text06']} ${projectStyles['section-Text']} `}
          >
            Merchant Bear is always there
          </span>
        </div>
        <div className={styles['Cards-container']}>
          <div className={styles['Feature-card']}>
            <img
              alt="image"
              src="/playground_assets/c-d-x-pdx_a_82obo-unsplash-300h.jpg"
              className={styles['image2']}
            />
            <span
              className={` ${styles['Heading']} ${projectStyles['card-Heading']} `}
            >
              Wireless Headphones
            </span>
            <span
              className={` ${styles['text07']} ${projectStyles['card-Text']} `}
            >
              <span>
                Never leave home without them.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                Wireless, Noise Cancelling, Excellent Battery Life. What more
                could you want?
              </span>
            </span>
            <div className={styles['container02']}>
              <div className={styles['container03']}>
                <span>
                  Was:
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className={styles['text12']}>
                  <span>
                    $129.95
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </div>
              <div className={styles['container04']}>
                <span>
                  Now:
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className={styles['text15']}>$66.95</span>
              </div>
              <span className={styles['text16']}>
                <span>You s</span>
                <span>ave: $63</span>
              </span>
            </div>
          </div>
          <div className={styles['Feature-card1']}>
            <img
              alt="image"
              src="/playground_assets/david-van-dijk-3ltht2nxd34-unsplash-300h.jpg"
              className={styles['image3']}
            />
            <span
              className={` ${styles['Heading1']} ${projectStyles['card-Heading']} `}
            >
              Standing Lamp
            </span>
            <span
              className={` ${styles['text19']} ${projectStyles['card-Text']} `}
            >
              Simple yet beautiful. Use them where ever you need, they&apos;ll
              never leave you in the dark.
            </span>
            <div className={styles['container05']}>
              <div className={styles['container06']}>
                <span>
                  Was:
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className={styles['text21']}>
                  <span>
                    $95.98
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </div>
              <div className={styles['container07']}>
                <span>
                  Now:
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className={styles['text24']}>$60.98</span>
              </div>
              <span className={styles['text25']}>
                <span>You s</span>
                <span>ave: $35</span>
              </span>
            </div>
          </div>
          <div className={styles['Feature-card2']}>
            <img
              alt="image"
              src="/playground_assets/oscar-ivan-esquivel-arteaga-ztxed1cpb1e-unsplash-300h.jpg"
              className={styles['image4']}
            />
            <span
              className={` ${styles['Heading2']} ${projectStyles['card-Heading']} `}
            >
              Bluetooth Wireless Mouse
            </span>
            <span
              className={` ${styles['text28']} ${projectStyles['card-Text']} `}
            >
              Leave your wires behind. Small, elegant, portable. Left handed?
              Don&apos;t worry, it&apos;s ambidextrous. Work, Communicate, and
              Play better.
            </span>
            <div className={styles['container08']}>
              <div className={styles['container09']}>
                <span>
                  Was:
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className={styles['text30']}>
                  <span>$35.87</span>
                </span>
              </div>
              <div className={styles['container10']}>
                <span>
                  Now:
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className={styles['text33']}>$28.87</span>
              </div>
              <span className={styles['text34']}>
                <span>You s</span>
                <span>ave: $7</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['Hero1']}>
        <img
          alt="image"
          src="/playground_assets/bench-accounting-mgafenpdcsw-unsplash-500h.jpg"
          className={styles['image5']}
        />
        <div className={styles['container11']}>
          <h1 className={styles['text37']}>Fast and Reliable Shipping</h1>
          <h2 className={styles['text38']}>
            No one likes to wait. With us, you don't have to
          </h2>
        </div>
      </div>
      <div className={styles['Section-separator1']}></div>
      <div className={styles['Testimonials']}>
        <h2
          className={` ${styles['text39']} ${projectStyles['section-Heading']} `}
        >
          What are they saying about us?
        </h2>
        <div className={styles['Cards-container1']}>
          <div className={styles['Testimonial-card']}>
            <div className={styles['Card-heading']}>
              <div className={styles['Name-and-Position']}>
                <span className={projectStyles['card-Heading']}>
                  William Gardener
                </span>
                <span
                  className={` ${styles['Position']} ${projectStyles['card-Text']} `}
                >
                  New York, NY
                </span>
              </div>
              <div className={styles['Stars-container']}>
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className={styles['icon02']}
                >
                  <path d="M950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 14.857-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z"></path>
                </svg>
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className={styles['icon04']}
                >
                  <path d="M950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 14.857-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z"></path>
                </svg>
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className={styles['icon06']}
                >
                  <path d="M950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 14.857-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z"></path>
                </svg>
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className={styles['icon08']}
                >
                  <path d="M950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 14.857-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z"></path>
                </svg>
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className={styles['icon10']}
                >
                  <path d="M649.714 573.714l174.857-169.714-241.143-35.429-108-218.286-108 218.286-241.143 35.429 174.857 169.714-41.714 240.571 216-113.714 215.429 113.714zM950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 15.429-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z"></path>
                </svg>
              </div>
            </div>
            <span
              className={` ${styles['text40']} ${projectStyles['card-Text']} `}
            >
              Great products, quick shipping. Not much else I can ask for.
              Thanks Merchant Bear!
            </span>
          </div>
          <div className={styles['Testimonial-card1']}>
            <div className={styles['Card-heading1']}>
              <div className={styles['Name-and-Position1']}>
                <span className={projectStyles['card-Heading']}>
                  Marie Taylor
                </span>
                <span
                  className={` ${styles['Position1']} ${projectStyles['card-Text']} `}
                >
                  Chicago, IL
                </span>
              </div>
              <div className={styles['Stars-container1']}>
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className={styles['icon12']}
                >
                  <path d="M950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 14.857-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z"></path>
                </svg>
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className={styles['icon14']}
                >
                  <path d="M950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 14.857-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z"></path>
                </svg>
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className={styles['icon16']}
                >
                  <path d="M950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 14.857-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z"></path>
                </svg>
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className={styles['icon18']}
                >
                  <path d="M950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 14.857-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z"></path>
                </svg>
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className={styles['icon20']}
                >
                  <path d="M950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 14.857-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z"></path>
                </svg>
              </div>
            </div>
            <span
              className={` ${styles['text41']} ${projectStyles['card-Text']} `}
            >
              Amazing service, keep it up Merchant Bear and you have a customer
              for life.
            </span>
          </div>
          <div className={styles['Testimonial-card2']}>
            <div className={styles['Card-heading2']}>
              <div className={styles['Name-and-Position2']}>
                <span className={projectStyles['card-Heading']}>
                  Bryan Kevin
                </span>
                <span
                  className={` ${styles['Position2']} ${projectStyles['card-Text']} `}
                >
                  Boston, MA
                </span>
              </div>
              <div className={styles['Stars-container2']}>
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className={styles['icon22']}
                >
                  <path d="M950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 14.857-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z"></path>
                </svg>
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className={styles['icon24']}
                >
                  <path d="M950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 14.857-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z"></path>
                </svg>
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className={styles['icon26']}
                >
                  <path d="M950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 14.857-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z"></path>
                </svg>
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className={styles['icon28']}
                >
                  <path d="M950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 14.857-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z"></path>
                </svg>
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className={styles['icon30']}
                >
                  <path d="M649.714 573.714l174.857-169.714-241.143-35.429-108-218.286-108 218.286-241.143 35.429 174.857 169.714-41.714 240.571 216-113.714 215.429 113.714zM950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 15.429-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z"></path>
                </svg>
              </div>
            </div>
            <span
              className={` ${styles['text42']} ${projectStyles['card-Text']} `}
            >
              I needed some supplies for College urgently, ordered from Merchant
              Bear and got exactly what I needed when I needed it. Good quality,
              awesome service
            </span>
          </div>
        </div>
      </div>
      <div id="about" className={styles['Contact']}>
        <div className={styles['Content-container']}>
          <h2
            className={` ${styles['text43']} ${projectStyles['section-Heading']} `}
          >
            Where to find us
          </h2>
          <div className={styles['Locations-container']}>
            <div className={styles['Location-2']}>
              <span className={styles['Heading3']}>Delaware Office</span>
              <div className={styles['Adress']}>
                <svg viewBox="0 0 1024 1024" className={styles['icon32']}>
                  <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
                </svg>
                <span className={projectStyles['section-Text']}>Lewes, DE</span>
              </div>
              <div className={styles['Email']}>
                <svg viewBox="0 0 1024 1024" className={styles['icon34']}>
                  <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                </svg>
                <span className={projectStyles['section-Text']}>
                  merchantbear@hotmail.com
                </span>
              </div>
            </div>
          </div>
          <a
            href="#contact"
            className={` ${styles['link4']} ${projectStyles['button']} `}
          >
            Contact Us
          </a>
        </div>
        <img
          alt="image"
          src="/playground_assets/merchant_bear_logo-700h.png"
          className={styles['image6']}
        />
      </div>
      <div id="contact" className={styles['Hero2']}>
        <img
          alt="image"
          src="/playground_assets/sigmund-lr3dcjzv_tc-unsplash-500w.jpg"
          className={styles['image7']}
        />
        <div className={styles['container12']}>
          <h1 className={styles['text46']}>Say Hi!</h1>
          <div className={styles['container13']}>
            <span className={styles['text47']}>
              <span>
                Full
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>Name:</span>
            </span>
            <input
              type="text"
              placeholder="First Last"
              className={projectStyles['input']}
            />
            <span className={styles['text50']}>Email Address:</span>
            <input
              type="text"
              placeholder="email@domain.com"
              className={projectStyles['input']}
            />
            <span className={styles['text51']}>Comment or Message:</span>
            <textarea
              placeholder="What's on your mind?"
              className={` ${styles['textarea']} ${projectStyles['textarea']} `}
            ></textarea>
          </div>
          <div className={styles['BtnGroup1']}>
            <button
              className={` ${styles['button']} ${projectStyles['button']} `}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
